import { Link } from "gatsby"
import React from "react"
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Badge from 'react-bootstrap/Badge'

const LargeCard = (props) => {
    return (
        <Row>
            <Col lg="7">
                <Badge variant="primary">{props.tag}</Badge>
                <Link to={props.link}><h3 className="font-weight-bold mb-3"><strong>{props.title}</strong></h3></Link>
                <p>{props.excerpt}</p>
                <p>by <a><strong>Andrej Jovanovic</strong></a>, 14/08/2018</p>
                <Link to={props.link}><Button className="btn btn-pink btn-md mb-lg-0 mb-4">Read more</Button></Link>
            </Col>

      

            <Col lg="5">
                <div className="view overlay rounded z-depth-2">
                <img className="img-fluid rounded" src={props.img} alt="Sample image"/>
                <a>
                    <div className="mask rgba-white-slight"></div>
                </a>
                </div>
            </Col>   
        </Row>
    );
};

export default LargeCard;