import React from "react"
import { usePostsMetadata } from '../hooks/usePostsMetadata'
import Layout from "../components/layout"
import SEO from "../components/seo"
import Excerpt from "../components/excerpt"
import LargeCard from "../components/LargeCard"
import LargeCardLeft from "../components/LargeCardLeft"
import 'bootstrap/dist/css/bootstrap.min.css';

const IndexPage = () => {
  
  const allMdx = usePostsMetadata();

  return(
  <Layout>
    <SEO title="Home" />
    {allMdx.map(({excerpt, frontmatter, slug}) => (
      <>
      <LargeCard title={frontmatter.title} excerpt={excerpt} link={slug} tag={frontmatter.tag} img={frontmatter.img}></LargeCard>
      <hr/>
      </>
    ))}
  </Layout>
  )
}

export default IndexPage
