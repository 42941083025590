import { graphql, useStaticQuery } from 'gatsby'
export const usePostsMetadata = () => {
  const { allMdx } = useStaticQuery(
    graphql`
    query MyQuery {
        allMdx {
          nodes {
            slug
            frontmatter {
              date
              title
              tag
              img
            }
            excerpt
          }
        }
      }      
    `
  )
  console.log(allMdx.nodes[0].excerpt)
  return allMdx.nodes
}